import {initTrustbars} from "../../../default/global/js/trustbar";
import {getChildrenClassForNestedItems} from "../../../default/global/js/nav";
import {closeFilterBox, initFilter} from "../../../nti-global/js/filter";
import {initActionClicks, initFormSubmitChanges, initFormSubmitClicks} from "../../../default/global/js/actionClick";
import {focusInputGroups} from "../../../default/global/js/checkInputGroups";
import {stickyHeader} from "../../../default/global/js/stickyheader";
import {triggerFavoritesBtns} from "../../../default/global/js/favorites";
import {initQuantitySpinners} from "../../../nti-global/js/quantitySpinner";
import hoverintent, {removeOtherHoverintents} from "../../../default/global/js/hoverintent";
import {initSlideshows} from "../../../default/global/js/slideshow";
import {openFullscreenGallery} from "../../../default/global/js/fullscreengallery";
import {stickyBox} from "../../../default/global/js/stickyBox.js";
import {buildMobileMenu, hideMobileMenu, showMobileMenu} from "../../../default/global/js/mobilemenu";
import {excludeModal, triggerConfirmModalLink, triggerModal} from "../../../default/global/js/modal";
import {triggerBasketBtns, triggerUpdateBasketView} from "../../../default/global/js/basket";
import {initToggleSlide} from "../../../default/global/js/toggle";
import {initItemlistSlider} from "../../../default/global/js/itemListSlider";
import {setSrcVideos} from "../../../default/global/js/video";
import {initScrollbars} from "../../../default/global/js/scrollbar";
import {isTouchDevice} from "../../../default/global/js/touchDevice";
import {initCustomSelects} from "../../../nti-global/js/customSelect";
import {initJumpmarks} from "../../../default/global/js/jumpmark";
import {initSearchItems} from "../../../default/global/js/searchItems";
import {initItemlistToggle} from "../../../default/global/js/itemlistToggle";
import {initPriceListGraduated} from "../../../default/global/js/priceListGraduated";
import {lottieLoader} from "../../../default/global/js/lottieLoader";
import {initFlashmessages} from "../../../default/global/js/flashmessages";
import {initPasswordFields} from "../../../default/global/js/password";
import {trimInputValues} from "../../../default/global/js/formValidator";
import {initSlidecontents} from "../../../default/global/js/slidecontent";
import {initPhoneCountryFields} from "../../../default/global/js/phoneCountryField";
import {initSearchFunctions, triggerSearchSuggestion} from "../../../nti-global/js/searchSuggestions";
import {initInputBody} from "../../../nti-global/js/inputfile";
import {initLogin} from "../../../nti-global/js/login";
import {initChronicSlideshow} from "../../../nti-b2b/src/js/custom/chronicSlider";
import {addTableWrapper} from "../../../default/global/js/tableWrapper";
import {initImageGallery} from "../../../default/global/js/imageGallery";
import {calculateStickyTop} from "../../../default/global/js/utilities/sticky";
import {initAjaxCollectionFilter} from "../../../default/global/js/ajaxCollectionFilter";
import {calculateSubnavHeight} from "../../../nti-global/js/subnav";
import {initRemoveUserCentricsButton} from "../../../nti-global/js/removeUserCentricsButton";
import {initFilegalleryFileDownload} from "./filegallery";
import {initRecaptchaForms} from "../../../default/global/js/recaptchaForm";

window.addEventListener("load", (event) => {
    const mobileMenu = document.querySelector('.mobileMenu');
    const mobileMenuToggle = document.getElementById("js-toggleNavigation");
    const overlay = document.getElementById("overlay");
    const IsTouchDevice = isTouchDevice();
    const stickyHeaderArea = document.querySelector('.headerMain');

    let breakPointSM = 1024;
    let sendFavoriteByJS = false;
    let sendItemToBasketByJS = false;
    let updateBasketByJS = false;
    let searchSuggestionByJS = true;

    if (typeof GLOBALS!== "undefined") {
        breakPointSM = GLOBALS.gridBreakpoints.sm;
        sendFavoriteByJS = GLOBALS.sendFavoriteByJS;
        //sendItemToBasketByJS = GLOBALS.sendItemToBasketByJS;
        updateBasketByJS = GLOBALS.updateBasketByJS;
    }

    if (mobileMenu && mobileMenuToggle && overlay) {
        const mobileMenuClose = mobileMenu.querySelector(".mobileMenu__close");

        buildMobileMenu(mobileMenu);

        mobileMenuToggle.addEventListener("click", function () {
            showMobileMenu(mobileMenu, overlay, mobileMenuToggle);
        });

        if(mobileMenuClose){
            mobileMenuClose.addEventListener("click", function () {
                hideMobileMenu(mobileMenu, overlay, mobileMenuToggle);
            });
        }

        overlay.addEventListener("click", function () {
            hideMobileMenu(mobileMenu, overlay, mobileMenuToggle);
            for (let filterbox of document.querySelectorAll('.filterBox')) {
                closeFilterBox(filterbox);
            }
        });
    }

    initLogin();

    //initTrustbars();

    initFilter();

    initActionClicks();

    initFormSubmitClicks();

    initFormSubmitChanges();

    focusInputGroups();

    trimInputValues();

    initInputBody();

    initPasswordFields();

    initPhoneCountryFields();

    initChronicSlideshow();

    addTableWrapper();

    stickyHeader(stickyHeaderArea);

    if (window.outerWidth < breakPointSM) {
        for (let parent of document.querySelectorAll('.navgroup > ul > li')) {
            getChildrenClassForNestedItems(parent);
        }
    }

    if (sendFavoriteByJS) {
        triggerFavoritesBtns();
    }

    if (searchSuggestionByJS) {
        triggerSearchSuggestion();
    }

    if (sendItemToBasketByJS) {
        triggerBasketBtns();
    }

    if (updateBasketByJS) {
        triggerUpdateBasketView();
    }

    triggerModal();
    excludeModal();
    triggerConfirmModalLink();

    initQuantitySpinners();

    initToggleSlide();

    initFlashmessages();

    if (!IsTouchDevice) {
        for (let el of document.querySelectorAll('.itembox,.iconbarItem')) {
            hoverintent(el);
        }
    }

    // sticky Kontaktbox
    hoverintent(document.querySelector('#contactBox'));


    if (IsTouchDevice) {
        for (let el of document.querySelectorAll('.js-mainNavigation > ul > li > a')) {
            el.addEventListener("click", function (e) {
                let li = el.closest("li");
                if (!li.classList.contains("hoverintent")) {
                    removeOtherHoverintents();
                    li.classList.add("hoverintent");
                    e.preventDefault();
                } else {
                    li.classList.remove("hoverintent");
                }
            });
        }
    } else {
        for (let el of document.querySelectorAll('.js-mainNavigation > ul > li')) {
            hoverintent(el);
        }
    }

    setSrcVideos();

    initSlideshows();

    initImageGallery();

    initScrollbars();

    initSlidecontents();

    initItemlistSlider(document.querySelectorAll('.itemboxList.swiper'),{
        breakpointItems: {
            xs: 1.7,
            sm: 3,
            md: 4,
            lg: 4,
            xlg: 5
        }
    });

    initCustomSelects();

    initSearchItems(document.querySelectorAll('.js-searchInput'));
    initSearchFunctions();

    let offsetTopJumpmarks = 0;
    if (stickyHeaderArea) {
        offsetTopJumpmarks = stickyHeaderArea.clientHeight;
    }

    if (typeof GLOBALS!== "undefined") {
        if(GLOBALS.offsetTopJumpmarks){
            offsetTopJumpmarks += GLOBALS.offsetTopJumpmarks;
        }
    }

    initJumpmarks(offsetTopJumpmarks);

    for (let item of document.querySelectorAll(".js-openGallery")) {
        item.addEventListener('click', function (event) {
            openFullscreenGallery(item);
        });
    }

    let offsetTopStickyBox = 30;
    if (stickyHeaderArea) {
        offsetTopStickyBox += stickyHeaderArea.clientHeight;
    }

    for (let pinBox of document.querySelectorAll(".js-stickyBox")) {
        stickyBox(pinBox, {
            offsetTop: offsetTopStickyBox
        });
    }

    calculateSubnavHeight(document.querySelector('.subnav'), 120);

    lottieLoader();

    initItemlistToggle();
    initPriceListGraduated();

    if(document.querySelector('.newsList.isFullView')) {
        calculateStickyTop(document.querySelector('.news__previewimage'), 20, document.querySelector('.headerMain'));
    }

    if(document.querySelector('.isFullView.pg_karriere')) {
        calculateStickyTop(document.querySelector('.stickyBox'), 20, document.querySelector('.headerMain'));
    }

    initAjaxCollectionFilter();

    initRemoveUserCentricsButton();

    initFilegalleryFileDownload();

    initRecaptchaForms();

    loadAndResize();
});

window.addEventListener('resize', ()=> {
    loadAndResize();
});

function loadAndResize() {
    calculateHoverMenuTop();
}

function calculateHoverMenuTop() {
    let headerHeight = document.querySelector('.headerMain').getBoundingClientRect().height;
    document.querySelector('.headerMainNavigation .hoverMenuContentContainer').closest('ul').style.top = headerHeight;
}