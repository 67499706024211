import {fadeIn, fadeOut} from "./utilities/fade";

function buildChildrenBackLink(wrapper) {
    let childrenBackLink = document.createElement("a");
    childrenBackLink.classList.add("mobileMenu__backLink");
    childrenBackLink.innerText = GLOBALS.tc.back;

    childrenBackLink.addEventListener("click", function () {
        childrenBackLink.closest(".hasChildren.is-open").classList.remove("is-open");
        let closestUl = childrenBackLink.closest(".hasChildren.is-open").querySelector("ul");
        if(closestUl){
            closestUl.scrollTop = 0;
            closestUl.style.overflow = "";
        }
    });

    wrapper.prepend(childrenBackLink);
}

function buildChildrenHeader(wrapper, childrenLink) {
    let childrenHeader = document.createElement("a");
    childrenHeader.classList.add("mobileMenu__subHeader");
    childrenHeader.innerText = childrenLink.innerText;
    if (childrenLink.tagName === 'A') {
        childrenHeader.href = childrenLink.href;
    }
    wrapper.prepend(childrenHeader);
}

function addClickEventMobileMenuLink(link) {
    let hasChildren = false;
    let childrenLink = false;
    let parent = link.closest("li");
    let childrenList = false;

    for (let children of parent.children) {
        if (children.tagName === "UL") {
            hasChildren = true;
            childrenList = children;
        }
        if (children.tagName === 'A' || children.tagName === 'SPAN') {
            childrenLink = children;
        }
    }

    if (hasChildren) {
        childrenLink.addEventListener("click", function (e) {
            let closestLi = childrenLink.closest('li');
            let closestUl = childrenLink.closest("ul");
            closestUl.scrollTop = 0;
            if (closestLi.classList.contains("is-open")) {
                closestLi.classList.remove("is-open");
                childrenLink.classList.remove("is-active");
                closestUl.style.overflow = "";
            } else {
                closestLi.classList.add("is-open");
                childrenLink.classList.add("is-active");
                closestUl.style.overflow = "hidden";
            }
            e.preventDefault();
        });

        parent.classList.add("hasChildren");

        if (childrenList) {
            buildChildrenHeader(childrenList, childrenLink);
            buildChildrenBackLink(childrenList);
        }
    }
}

export function buildMobileMenu(menu) {
    if (menu) {
        for(let menuNavigation of menu.querySelectorAll(".mobileMenu__navigation--shop")){
            for (let link of menuNavigation.querySelectorAll('a')) {
                addClickEventMobileMenuLink(link);
            }
            for (let activeUl of menuNavigation.querySelectorAll('.is-active,.is-active-tree')) {
                activeUl.classList.add("is-open");
            }
        }
        for(let menuNavigation of menu.querySelectorAll(".mobileMenu__navigation--content")){
            for (let link of menuNavigation.querySelectorAll('a')) {
                addClickEventMobileMenuLink(link);
            }
            for (let activeUl of menuNavigation.querySelectorAll('.is-active,.is-active-tree')) {
                activeUl.classList.add("is-open");
            }
        }
    }
}

export function showMobileMenu(menu, overlay, toggle) {
    fadeIn(overlay, 200, "linear", function () {
        menu.classList.add("is-open");
        toggle.classList.add("is-active");
        document.body.classList.add("is-mobilemenuopen");
    });
}

export function hideMobileMenu(menu, overlay, toggle) {
    menu.classList.remove("is-open");
    toggle.classList.remove("is-active");
    document.body.classList.remove("is-mobilemenuopen");
    fadeOut(overlay);
}