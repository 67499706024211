import {
    querySelectorAllDeep,
    querySelectorDeep,
} from "query-selector-shadow-dom";



export function initRemoveUserCentricsButton() {

    setTimeout(() => {
        querySelectorDeep('#usercentrics-root button').style.display = 'none';
        document.getElementById('usercentrics-root').style.display = 'block';
    },1000);
}

