import {addErrorClass, addLoadingClass, removeLoadingClass} from "./utilities/updateClasses";
import {wrap} from "./utilities/wrap";
import {initSlidecontents} from "./slidecontent";
import {initFilegalleryFileDownload} from "../../../nti-catalog/src/js/filegallery";

function setActiveClassForFilter(btn,wrapper){
    for(let btn of wrapper.querySelectorAll(".js-collectionFilter .tag")){
        btn.classList.remove("is-active");
    }
    btn.classList.add("is-active");
}

export function initAjaxCollectionFilter(){
    for(let btn of document.querySelectorAll(".js-collectionFilter .tag")){
        let wrapper = btn.closest(".collection");
        if(wrapper){
            let wrapperId = wrapper.getAttribute("id");
            let area = wrapper.querySelector(".collectionArea");
            if(area){
                btn.addEventListener("click", function (e){
                    e.preventDefault();
                    addLoadingClass(btn);
                    addLoadingClass(area);
                    let filterID = parseInt(btn.dataset.filter);
                    fetch("?group_filter="+filterID)
                        .then((response) => {
                            if (!response.ok) {
                                throw new Error("Could not filter data");
                            }
                            return response.text();
                        })
                        .then((data) => {
                            let parser = new DOMParser();
                            let doc = parser.parseFromString(data, "text/html");
                            let newWrapper = doc.getElementById(wrapperId);
                            if(newWrapper){
                                let newArea = newWrapper.querySelector(".collectionArea");
                                if(newArea){
                                    area.innerHTML = newArea.innerHTML;
                                    removeLoadingClass(btn);
                                    removeLoadingClass(area);
                                    initFilegalleryFileDownload();
                                    setActiveClassForFilter(btn,wrapper);
                                    initSlidecontents();
                                    window.history.replaceState(null, null, "?group_filter="+filterID);
                                }else{
                                    console.error('Area not found');
                                }
                            }else{
                                console.error('Wrapper not found');
                            }
                        })
                        .catch((e) => {
                            console.error('Error:', e);
                            addErrorClass(btn);
                            addErrorClass(area);
                        });
                })
            }
        }
    }
}