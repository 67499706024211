import {fireChangeEvent} from "../../default/global/js/utilities/fireEvent";
import {fadeIn,fadeOut} from "../../default/global/js/utilities/fade";
import {isVisible} from "../../default/global/js/utilities/visible";

function showCustomSelect(customSelect) {
    if (customSelect) {
        if (window.outerWidth < 768) {
            fadeIn(document.getElementById("overlay"));
        }else{
            const dropdown = customSelect.querySelector(".customSelect__dropdown");
            if(dropdown) {
                dropdown.style.display = "block";
                setDropdownPosition(customSelect);
                dropdown.style.display = "";
            }
        }
        customSelect.classList.add("is-active");
    }
}

export function hideCustomSelect(customSelect) {
    if (customSelect) {
        if(isVisible(document.getElementById("overlay"))){
            fadeOut(document.getElementById("overlay"));
        }

        resetDropdownPosition(customSelect);
        customSelect.classList.remove("is-active");
    }
}

function hideAllCustomSelect() {
    for (let customSelect of document.querySelectorAll('.customSelect')) {
        hideCustomSelect(customSelect);
    }
}

function setActiveValueCustomSelect(input, selectedTrigger, dropdownItems, value = "") {
    input.value = value;
    let selectedText = "";
    let hasActive = false;
    for (let item of dropdownItems) {
        if (item.dataset.value === value) {
            item.classList.add("is-active");
            selectedText = item.querySelector('.customSelect__dropdownItemHeadline').innerHTML;
            hasActive = true;
        } else {
            item.classList.remove("is-active");
        }
    }
    if (hasActive) {
        selectedTrigger.innerHTML = selectedText;
    }
}

function setActiveValueCustomSelectMultiple(input, selectedTrigger, dropdownItems) {
    let currentValues = input.value.split("|");
    let countSelected = selectedTrigger.querySelector('.customSelect__selectedCount');
    let hasActive = false;
    for (let item of dropdownItems) {
        if (currentValues.includes(item.dataset.value)) {
            item.classList.add("is-active");
            hasActive = true;
        } else {
            item.classList.remove("is-active");
        }
    }
    currentValues = currentValues.filter(Boolean);
    if (currentValues[0] === "0") {
        currentValues = [];
    }
    if (countSelected) {
        if (currentValues.length > 0) {
            countSelected.classList.add("is-active");
            countSelected.innerHTML = currentValues.length;
        } else {
            countSelected.classList.remove("is-active");
        }
    }
    if (!hasActive) {
        input.value = 0;
    }
}

function setDropdownPosition(dropdownSelect) {

    if (window.innerWidth < 786) {
        return;
    }

    let dropdown = dropdownSelect.querySelector('.customSelect__dropdown');

    const dropdownBound = dropdown.getBoundingClientRect();
    const dropdownRight = dropdownBound.right;
    const dropdownBottom = dropdownBound.bottom;
    const dropdownHeight = dropdownBound.height;
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const margin = 15;

    const stickyHeader = document.querySelector('.headerMain.sticky');
    let spaceOnTopAvailable;
    if ( stickyHeader ) {
        spaceOnTopAvailable = (dropdownBound.top - 50 - stickyHeader.getBoundingClientRect().height) > dropdownHeight;

    } else {
        spaceOnTopAvailable = (dropdownBound.top - 50) > dropdownHeight;
    }

    if ( (windowWidth - margin - dropdownRight) < 0 ) {
        dropdownSelect.classList.add('customSelect--openRight');
    }

    if ((windowHeight - margin - dropdownBottom) < 0 && spaceOnTopAvailable) {
        dropdownSelect.classList.add('customSelect--openTop');
    }

}

function resetDropdownPosition(dropdownSelect) {

    dropdownSelect.classList.remove('customSelect--openRight');
    dropdownSelect.classList.remove('customSelect--openTop');

}

export function initCustomSelects(query = document.querySelectorAll(".js-customSelect")) {
    for (let customSelect of query) {
        const input = customSelect.querySelector(".customSelect__hiddenFormField");
        const selectedTrigger = customSelect.querySelector('.customSelect__selected');
        const dropdown = customSelect.querySelector(".customSelect__dropdown");
        const dropdownItems = dropdown.querySelectorAll('.customSelect__dropdownItem:not(.disabled)');
        const reset = dropdown.querySelector('.customSelectReset');
        const isMultiple = customSelect.classList.contains("customSelect--multiple");
        const splitter = "|";

        if (input && selectedTrigger && dropdown) {
            if (dropdownItems) {
                if (isMultiple) {
                    setActiveValueCustomSelectMultiple(input, selectedTrigger, dropdownItems);
                } else {
                    setActiveValueCustomSelect(input, selectedTrigger, dropdownItems, input.value);
                }
                for (let dropdownItem of dropdownItems) {
                    dropdownItem.addEventListener("click", function () {
                        let value = dropdownItem.dataset.value;
                        if (value) {
                            if (isMultiple) {
                                if (dropdownItem.classList.contains("is-active")) {
                                    let currentValues = '';
                                    if (input.value !== '0') {
                                        currentValues = input.value.split(splitter);
                                        let index = currentValues.indexOf(value);
                                        currentValues.splice(index, 1);
                                        input.value = currentValues.join(splitter);
                                    } else {
                                        input.value = value;
                                    }
                                } else {
                                    if (input.value !== '0') {
                                        let currentValues = input.value.split(splitter);
                                        currentValues.push(value);
                                        input.value = currentValues.join(splitter);
                                    } else {
                                        input.value = value;
                                    }
                                }
                                setActiveValueCustomSelectMultiple(input, selectedTrigger, dropdownItems);
                            } else {
                                setActiveValueCustomSelect(input, selectedTrigger, dropdownItems, value);
                                fireChangeEvent(input);
                            }
                        }
                    });
                }

            }

            if(reset){
                reset.addEventListener("click", function (e) {
                    input.value = 0;
                    if (isMultiple) {
                        setActiveValueCustomSelectMultiple(input, selectedTrigger, dropdownItems);
                    } else {
                        setActiveValueCustomSelect(input, selectedTrigger, dropdownItems, "");
                        fireChangeEvent(input);
                    }
                });
            }
        }
    }

    document.addEventListener("click", function (e) {
        if (e.target.closest(".customSelect")) {
            //close select after set option
            let el = e.target.closest(".customSelect");
            if (e.target.closest('.customSelect__dropdownClose')) {
                hideCustomSelect(el);
            } else if (e.target.closest('.customSelect__selected') && el.classList.contains("is-active")) {
                hideCustomSelect(el);
            } else {
                if ((e.target.closest('.customSelect__dropdownItem:not(.disabled)')) && !el.classList.contains("customSelect--multiple")) {
                    hideCustomSelect(el);
                } else {
                    if (el.classList.contains("is-active") && !el.classList.contains("customSelect--multiple")) {
                        hideCustomSelect(el);
                    } else {
                        hideAllCustomSelect();
                        showCustomSelect(el);
                    }
                }
            }
        }else{
            hideAllCustomSelect();
        }
    });
}