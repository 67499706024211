import {fadeIn, fadeOut} from "../../default/global/js/utilities/fade";
import {showModal} from "../../default/global/js/modal";

export function initLogin() {
    initToggleCustomerNoField();

    // Show login modal
    const loginUrlParams = new URLSearchParams(window.location.search);
    const displayCustomerNoField = loginUrlParams.get('display_customer_no_field');
    if(displayCustomerNoField === "true") {

        // Show modal
        showModal(document.getElementById("modalLogin"));

        const showCustomerNoField = document.getElementById('show_customer_no_field_button');

        if(!showCustomerNoField.checked) {
            showCustomerNoField.checked = true;
            const onChangeEvent = new Event("change");
            showCustomerNoField.dispatchEvent(onChangeEvent);
        }
    }


}

function initToggleCustomerNoField() {

    const customerField = document.getElementById('input_customer_no');

    if(!customerField) {
        return;
    }

    let customerFieldinfeldGroup = customerField.closest('.infieldInputGroup');

    customerFieldinfeldGroup.style.display = 'none';

    setTimeout(() => {


        document.getElementById('show_customer_no_field_button').addEventListener("change", () => {

            if (document.getElementById('show_customer_no_field_button').checked === true) {

                fadeIn(customerFieldinfeldGroup);

            } else {

                fadeOut(customerFieldinfeldGroup);

            }

        });

        if (window.location.href.indexOf('display_customer_no_field=true') > -1) {

            fadeIn(customerFieldinfeldGroup);

        } else {

            fadeOut(customerFieldinfeldGroup);

        }


    }, 1000);

}
