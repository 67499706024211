import {XhrService} from "../../default/global/ts/xhr.service";
import {addErrorClass, addLoadingClass, addSuccessClass} from "../../default/global/js/utilities/updateClasses";
import {isTouchDevice} from "../../default/global/js/touchDevice";

function getSearchSuggestion(searchTerm, beforeSend, successSend, errorSend) {
    const fd = new FormData();
    fd.append("searchTerm", searchTerm);

    XhrService.generalAjaxCall('get_search_suggestion', fd, 'POST', 'json').then(res => {
        if (typeof successSend !== 'undefined') {
            successSend(res);
        }
    }, err => {
        console.error(err);
        if (typeof errorSend !== 'undefined') {
            errorSend();
        }
    });
}

function initEmptySearchField() {

    const searchField = document.querySelector('.js-getSearchSuggestion');

    document.querySelector('.js-emptySearch').addEventListener('click', () => {
        searchField.value = '';
        searchField.focus();
        document.querySelector('.js-search-results-wrapper').classList.remove('is-open');
    });

}

export function initSearchFunctions() {

    const searchButton = document.querySelector('.js-openSearch');
    const searchField = document.querySelector('.iconbarIcon--searchBar');
    const searchFormInput = document.querySelector('.js-getSearchSuggestion');
    let searchOpen = false;

    if (isTouchDevice()) {

        searchButton.addEventListener('click', () => {
            if(!searchOpen) {
                searchField.classList.add('is-open');
                searchOpen = true;
            } else {
                searchField.classList.remove('is-open');
                searchOpen = false;
            }
        });

        document.addEventListener('click', (e) => {
            if (!document.querySelector('.iconbarHover--searchBar').contains(e.target) && !document.querySelector('.iconbarItem--searchBar').contains(e.target)) {
                if(searchField.classList.contains('is-open')) {
                    searchField.classList.remove('is-open');
                    searchOpen = false;
                }
            }
        });

    }

    searchButton.addEventListener('click', () => {
        searchFormInput.focus();
    });
}



export function triggerSearchSuggestion() {
    let timer = 0;
    let ms = 400;

    const searchResultWrapper = document.querySelector('.js-search-results-wrapper');
    initEmptySearchField();

    let gss = document.getElementsByClassName("js-getSearchSuggestion");
    if (gss && gss.length > 0) {
        gss[0].addEventListener("keyup", function (e) {
            let target = e.target;
            clearTimeout(timer);
            timer = setTimeout(() => {
                let searchTerm = target.value;
                if (searchTerm && searchTerm.length >= 3) {
                    getSearchSuggestion(searchTerm, () => {
                        addLoadingClass(target);
                    }, (result) => {
                        if (result && result.resultHTML) {
                                let resultDiv = document.getElementsByClassName("searchSuggestions__result");
                                if (resultDiv && resultDiv.length > 0) {
                                    resultDiv[0].innerHTML = result.resultHTML;
                                    searchResultWrapper.classList.add('is-open');
                                }
                            }
                        }, () => {
                        addErrorClass(target);
                    });
                } else if (searchTerm && searchTerm.length < 3 || searchTerm == "") {
                    let resultDiv = document.getElementsByClassName("searchSuggestions__result");
                    if (resultDiv && resultDiv.length > 0) {
                        resultDiv[0].innerHTML = "";
                        searchResultWrapper.classList.remove('is-open');
                    }
                }
                e.preventDefault();
            }, ms);
        });
    }
}